<template>
  <div class="dropdown-container">
    <div class="main-dropdown">
      <v-select
        v-model="selectedItem"
        :options="options"
        class="custom-select"
        :clearable="clearable"
        :searchable="false"
        :selectable="(option) => !option.disabled"
      >
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    value: [ Object, String ],
    options: [],
    passedParentObject: { type: Object, default: null},
    clearable: { type: Boolean, default: true},
  },
  watch: {
    selectedItem(item) {
      if(this.passedParentObject) {
        this.$emit('changeWithParent', { item, parent: this.passedParentObject } )
      } else {
        this.$emit('input', item)
      }
    }
  },
  data() {
    return {
      selectedItem: this.value,
    }
  },
}
</script>
