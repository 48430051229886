<template>
  <div
    class="table-bullet"
    :class="[
      getCollorClass() ? getCollorClass() : '',
      number ? 'numbered' : '',
    ]"
  >
    <span v-if="number">{{ number }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableBullet',
  props: {
    value: { type: [String, Number, Boolean], default: null },
    number: { type: Number, default: null },
  },
  methods: {
    getCollorClass() {
      if (this.value === 'CLOSED' || this.value === true) {
        return 'green'
      }
      if (this.value === 'PROCESSING' || this.value === 'WAREHOUSE') {
        return 'orange'
      }
      if (this.value === 'POSTPONED') {
        return 'purple'
      }
      if (this.value === 'UNABLE_TO_DELIVERED' || this.value === 'CANCELLED' || this.value === false) {
        return 'red'
      }
      return ''
    },
  },
}
</script>
