<template>
    <div class="admin-orders-listing">
        <title-bar addBtn exportBtn title="Orders" addUrl="/orders/add-order"/>
        <b-tabs
            pills
            class="order-listing-tabs"
            v-model="tabIndex"
            @input="loadOrders"
        >
            <div class="table-filter row mb-4">
                <div class="col-6 table-filter--column">
                    <div class="table-filter--label">
                        Fulltext search
                    </div>
                    <div class="table-filter--input">
                        <search class="mb-4" @inputChange="inputChange"/>
                    </div>
                </div>
                <div class="col-3 table-filter--column">
                    <div class="table-filter--label">
                        Order status
                    </div>
                    <div class="table-filter--input">
                        <custom-select
                            value="orderStatus"
                            v-model="orderStatus"
                            :options="orderStatuses"
                            @input="loadOrders"
                            :clearable="true"
                            ref="orderStatus"
                        />
                    </div>
                </div>
                <div class="col-3 table-filter--column">
                    <div class="table-filter--label">
                        Order date
                    </div>
                    <div class="table-filter--input">
                        <flat-pickr
                            v-model="orderDates"
                            @on-close="loadOrders"
                            :config="{
                            mode: 'range',
                            altFormat: 'd.m.Y H:i',
                            dateFormat: 'Z',
                            altInput: true,
                            altInputClass: 'flatpickr-input',
                            time_24hr: true,
                            enableTime: true,
                          }"
                        ></flat-pickr>
                    </div>
                </div>
            </div>
            <b-tab
                v-for="(tab, tabIndex) of tabs"
                :key="tabIndex"
                :title="tab.title"
                href="#new"
            >
                <vue-good-table
                    class="custom-table-style"
                    mode="remote"
                    :totalRows="pages"
                    :isLoading.sync="isLoading"
                    :columns="columns"
                    :rows="rows"
                    :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: currentPage,
          }"
                    @on-page-change="onPageChange"
                >
                    <template slot="table-row" slot-scope="props">
            <span
                :class="props.column.field"
                v-if="props.column.field == 'status'"
            >
              <table-bullet :value="props.formattedRow[props.column.field]"/>
            </span>
                        <span
                            :class="props.column.field"
                            v-else-if="props.column.field == 'shippingAddress'"
                        >
              <div class="order-info">
                <div class="order-info-flex">
                  <div class="time-container">
                    <span class="number">#{{ props.row.id }}</span>
                    <div class="time">
                        <span>{{ props.row.createdAt | moment('DD. MM. YYYY HH:ss') }}</span>
                      <img
                          src="@/assets/images/icons/arrow-right-small.svg"
                          alt="arrow-right"
                      />
                      <span v-if="props.row.status === 'NEW'">Unknown</span>
                      <span v-else-if="props.row.status === 'CLOSED'">{{
                              props.row.closedAt | moment('HH:ss')
                          }}</span>
                      <span v-else>{{
                              $helper.getEnumTranslation(
                                  'order_status',
                                  props.row.status,
                              )
                          }}</span>
                    </div>
                  </div>
                  <div class="name">
                    {{
                          `${props.formattedRow[props.column.field].streetAddress} ${props.formattedRow[props.column.field].streetNumber ? props.formattedRow[props.column.field].streetNumber : ''} ${props.formattedRow[props.column.field].postalCode}`
                      }}
                  </div>
                  <div
                      class="place"
                      v-if="
                      props.formattedRow[props.column.field].flatNumber ||
                      props.formattedRow[props.column.field].floorNumber ||
                      props.formattedRow[props.column.field].gate
                    "
                  >
                    <span
                        v-if="props.formattedRow[props.column.field].gate"
                        class="entrance"
                    >Entrance
                      {{
                            props.formattedRow[props.column.field].gate
                        }}</span
                    >
                    <span
                        v-if="props.formattedRow[props.column.field].flatNumber"
                        class="flat"
                    >Flat
                      {{
                            props.formattedRow[props.column.field].flatNumber
                        }}</span
                    >
                    <span
                        v-if="props.formattedRow[props.column.field].floorNumber"
                        class="floor"
                    >{{ props.formattedRow[props.column.field].floorNumber }}.
                      floor</span
                    >
                  </div>
                  <div
                      v-if="props.row.shippingDate"
                  >Delivery time: {{
                          `${$helper.formatDate(props.row.shippingDate)} ${$helper.formatTime(props.row.shippingDate)}`
                      }}
                  </div>
                </div>
              </div>
            </span>
                        <span
                            :class="props.column.field"
                            v-else-if="props.column.field == 'totalPrice'"
                        >
              {{ props.formattedRow[props.column.field] | price }}
            </span>
                        <span
                            :class="props.column.field"
                            v-else-if="props.column.field == 'paymentMethod'"
                        >
              {{
                                $helper.getEnumTranslation(
                                    'order_payment_method',
                                    props.row.paymentMethod,
                                )
                            }}
            </span>
                        <span
                            :class="props.column.field"
                            v-else-if="props.column.field == 'user'"
                        >
              <div class="contact">
                <div class="contact-info">
                  <span class="name">
                    {{
                          props.formattedRow[props.column.field].givenName ? props.formattedRow[props.column.field].givenName : ''
                      }}
                    {{
                          props.formattedRow[props.column.field].familyName ? props.formattedRow[props.column.field].familyName : ''
                      }}
                  </span>
                  <span
                      v-if="props.row.telephone"
                      class="number"
                  >{{ props.row.telephone }}</span
                  >
                  <span
                      v-if="props.formattedRow[props.column.field].email"
                      class="email"
                  >{{ props.formattedRow[props.column.field].email }}</span
                  >
                </div>
                <call-btn
                    v-if="props.row.telephone"
                    :number="props.row.telephone"
                />
              </div>
            </span>
                        <span v-else-if="props.column.field == 'courier'">
              <div class="dropdown-container">
                <div class="main-dropdown">
                  <b-form-group>
                    <v-select
                        v-model="props.row.courierObj"
                        :options="couriers"
                        class="custom-select"
                        :clearable="(props.row.courierObj && props.row.courierObj.id) ? true : false"
                        :searchable="true"
                        @input="updateCourier(props.row)"
                    >
                      <template slot="selected-option" slot-scope="option">
                        <div>
                          <img
                              v-if="
                              props.row.courier && props.row.courier.fileUrl
                            "
                              class="select-photo"
                              :src="`${props.row.courier.fileUrl}`"
                              alt=""
                          />
                          <span>{{ option.label }}</span>
                        </div>
                      </template>
                      <template #option="{ label }">
                        <img
                            v-if="props.row.courier && props.row.courier.fileUrl"
                            class="select-photo"
                            :src="`${props.row.courier.fileUrl}`"
                            alt=""
                        />
                        <span> {{ label }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </div>
              </div>
            </span>
                        <span class="buttons" v-else-if="props.column.field == 'id'">
              <div class="just-buttons">
                <edit-btn :path="'/orders/' + props.row.id + '/edit'"/>
                <delete-btn @pressDelete="deleteOrder(props.row.id)"/>
                <b-button
                    @click="windowResize"
                    v-b-toggle="
                    `collapse-${props.formattedRow[props.column.field]}`
                  "
                    class="custom-btn custom-collapse"
                >
                  <img src="@/assets/images/icons/arrow-up.svg"/>
                </b-button>
              </div>
              <div class="collapsible-content-row">
                <b-collapse
                    :id="`collapse-${props.formattedRow[props.column.field]}`"
                >
                  <div
                      v-for="(item, index) of props.row.items"
                      :key="index"
                      class="ordered-item"
                  >
                    <div class="item">
                      <div class="amount">{{ item.quantity }} pcs</div>
                      <div class="item-name">{{ item.name }}</div>
                    </div>
                    <div class="item-price">{{ item.price | price }}</div>
                  </div>

                  <div class="total-order-price">
                    <span>Total</span>
                    <span>{{ props.row.totalPrice | price }}</span>
                  </div>

                  <div v-if="props.row.description" class="order-comment">
                    <span>Comment</span>
                    <p class="comment-text">{{ props.row.description }}</p>
                  </div>
                </b-collapse>
              </div>
            </span>
                    </template>

                    <template slot="pagination-bottom" slot-scope="props">
                        <table-pagination
                            v-model="currentPage"
                            :total="pages"
                            :per-page="perPage"
                            :pageChanged="props.pageChanged"
                            :perPageChanged="props.perPageChanged"
                        />
                    </template>
                </vue-good-table>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import {BButton, BTabs} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import TitleBar from '@/components/TitleBar.vue'
import TableBullet from '@/components/Table/TableBullet.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import Search from '@/components/Inputs/Search.vue'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import ListUtils from '@/mixins/ListUtils'
import Toast from '@/components/Toast.vue'
import CustomSelect from '@/components/Inputs/CustomSelect.vue'
import 'flatpickr/dist/flatpickr.css'

export default {
    name: 'AdminOrdersListing',
    components: {
        BButton,
        CustomSelect,
        TitleBar,
        BTabs,
        Search,
        VueGoodTable,
        TableBullet,
        TablePagination,
        CallBtn,
        EditBtn,
        DeleteBtn,
        flatPickr,
    },
    mixins: [ListUtils],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            isLoading: false,
            tabs: [
                {
                    title: 'Pending orders',
                },
                {
                    title: 'Order history',
                },
            ],
            tabIndex: 0,
            perPage: 15,
            currentPage: 1,
            pages: 0,
            couriers: [],
            pendingStates: ['NEW', 'PROCESSING', 'POSTPONED'],
            historyStates: ['CANCELLED', 'CLOSED', 'UNABLE_TO_DELIVERED'],
            search: '',
            columns: [
                {
                    field: 'status',
                    width: '70px',
                    sortable: false,
                },
                {
                    field: 'shippingAddress',
                    sortable: false,
                },
                {
                    field: 'totalPrice',
                    type: 'number',
                    sortable: false,
                },
                {
                    field: 'paymentMethod',
                    sortable: false,
                },
                {
                    field: 'user',
                    sortable: false,
                },
                {
                    field: 'customer',
                    sortable: false,
                },
                {
                    field: 'courier',
                    sortable: false,
                },
                {
                    field: 'id',
                    sortable: false,
                    tdClass: 'collapsible',
                    width: '50px',
                },
            ],
            rows: null,
            tick: 0,
            orderStatus: null,
            orderStatuses: [],
            orderDates: null
        }
    },
    created() {
        window.addEventListener('resize', this.windowResize)
        this.getUserList(
            {params: {role: ['ROLE_COURIER'], 'order[familyName]': 'asc', onOff: true}},
            this.couriers,
        )
        const today = new Date()
        const yesterday = new Date(today.getTime())
        yesterday.setDate(yesterday.getDate() - 7)
        this.orderDates = `${this.formatDate(yesterday)}T16:00:00.000Z to ${this.formatDate(today)}T03:30:00.000Z`
        this.loadOrders()
        this.getEnumList('order_status', this.orderStatuses)
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize)
    },
    mounted() {
        this.$nextTick(() => {
            this.windowResize()
        })
    },
    watch: {
        isLoading(newVal, oldVal) {
            if (!oldVal && newVal) {
                this.windowResize()
            }
        },
    },
    methods: {
        windowResize() {
            const w = document.querySelectorAll('.vgt-table')[this.tabIndex].offsetWidth
            const collapsibleRows = document.querySelectorAll(
                '.collapsible-content-row',
            )
            collapsibleRows.forEach(row => {
                const bw = row.closest('.buttons').clientWidth
                row.style.width = `${w}px`
                row.style.marginLeft = `-${w - bw}px`

                let h = 0
                const cols = row.closest('tr').querySelectorAll('td')
                cols.forEach(col => {
                    const span = col.querySelector(':scope > span:not(.buttons)')
                    if (span && h < span.clientHeight) {
                        h = span.clientHeight
                    }
                })
                row.style.marginTop = `${h - 30}px`
            })
        },
        loadOrders() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                'order[createdAt]': 'asc',
            }
            if (this.tabIndex === 0) {
                params.status = this.pendingStates
            } else {
                params.status = this.historyStates
            }
            if (this.search !== '') {
                params.search = this.search
            }
            if (this.orderDates) {
                params['order[createdAt]'] = 'desc'
                const splitDate = this.orderDates.split(' to ')
                // eslint-disable-next-line prefer-destructuring
                const dateAfter = new Date(splitDate[0])
                params['createdAt[after]'] = new Date(
                    dateAfter.getTime() + Math.abs(dateAfter.getTimezoneOffset() * 60000)
                )
                // eslint-disable-next-line prefer-destructuring
                const dateBefore = new Date(splitDate[1])
                params['createdAt[before]'] = new Date(
                    dateBefore.getTime() + Math.abs(dateBefore.getTimezoneOffset() * 60000)
                )
            }
            if (this.orderStatus) {
                params.status = this.orderStatus.id
            }
            this.$Orders.getCollection({params}, 'order_list').then(response => {
                this.rows = response.data['hydra:member']
                this.pages = response.data['hydra:totalItems']
                this.rows.forEach(order => {
                    order.courierObj = {
                        label: order.courier
                            ? `${order.courier.givenName} ${order.courier.familyName}`
                            : 'Courier not selected',
                        id: order.courier ? order.courier['@id'] : null,
                        numberId: order.courier ? order.courier.id : null,
                    }
                })
                this.windowResize()
            })
        },
        deleteOrder(id) {
            this.$Orders.deleteResource({id}).then(response => {
                if (response.status === 204) {
                    this.$helper.showToast(
                        this.$toast,
                        Toast,
                        'Order deleted',
                        '',
                        'success',
                    )
                    this.loadOrders()
                }
            })
        },
        updateCourier(order) {
            const body = {
                courier: (order.courierObj) ? order.courierObj.id : null
            }
            this.$Orders
                .updateResource({id: order.id, body})
                .then(response => {
                    if (response.status === 200) {
                        this.$helper.showToast(
                            this.$toast,
                            Toast,
                            'Courier updated',
                            '',
                            'success',
                        )
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.$helper.showToast(
                            this.$toast,
                            Toast,
                            error.response.data['hydra:title'],
                            error.response.data['hydra:description'],
                            'danger',
                        )
                    }
                })
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0')
        },
        formatDate(date) {
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-')
        },
        inputChange(value) {
            this.search = value
            this.loadOrders()
        },
        onPageChange() {
            if (this.tick > 1) {
                this.loadOrders()
            }
            this.tick += 1
        },
    },
}
</script>
