import enums from '@/locales/enum-en.json'

export default {
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            locations: JSON.parse(localStorage.getItem('viennaStreets'))
        }
    },
    methods: {
        getEnumList(names, ...lists) {
            if (!(names instanceof Array)) {
                names = [names]
            }
            this.$Enums.getCollection({ params: { name: names } })
                .then(response => {
                    names.forEach((name, index) => {
                        response.data['hydra:member'].forEach(element => {
                            if (name === element.name) {
                                element.keys.forEach(key => {
                                    lists[index].push({
                                        label: enums[name][key],
                                        id: key,
                                    })
                                })
                            }
                        })
                    })
                })
        },
        getLocationList() {
            return new Promise(resolve => {
                let list = []
                if (this.locations && this.locations.length) {
                    list = this.locations
                } else {
                    this.$Streets.getCollection({}).then(response => {
                        response.data['hydra:member'].forEach(element => {
                            list.push({
                                id: element.id,
                                district: element.district,
                                streetAddress: element.streetAddress,
                                postalCode: element.postalCode,
                                city: 'Vienna',
                            })
                        })
                        localStorage.setItem('viennaStreets', JSON.stringify(list))
                    })
                }
                resolve(list)
            })
        },
        getUserList(args, ...lists) {
            const { params } = args

            params.pagination = false
            if (!(params.role instanceof Array)) {
                params.role = [params.role]
            }

            this.$Users.getCollection({ params }, 'user_list')
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        params.role.forEach((role, index) => {
                            if (role === element.role) {
                                lists[index].push({
                                    label: `${element.givenName} ${element.familyName}`,
                                    id: element['@id'],
                                    numberId: element.id,
                                })
                            }
                        })
                    })
                })
        },
        getUserPhoneList(args, ...lists) {
            const { params } = args

            if (!(params.role instanceof Array)) {
                params.role = [params.role]
            }

            this.$Users.getCollection({ params }, 'user_phone_list')
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        params.role.forEach((role, index) => {
                            if (role === element.role && element.telephone) {
                                lists[index].push({
                                    label: `${element.telephone}`,
                                    id: element['@id'],
                                    numberId: element.id,
                                })
                            }
                        })
                    })
                })
        },
    },
}
