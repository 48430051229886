<template>
  <a @click="modalShow = true" class="custom-btn delete-btn">
    <img src="@/assets/images/icons/bin.svg" alt="bin-ico" />
    <b-modal
        v-model="modalShow"
        modal-class="delete-dialog"
        cancel-title="Cancel"
        ok-title="Delete"
        @ok="confirmDelete"
      >
        <h3>Are you sure you want to delete this item?</h3>
      </b-modal>
  </a>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'DeleteBtn',
  components: {
    BModal
  },
  data() {
    return {
      modalShow: false,
    }
  },
  methods: {
    confirmDelete() {
      this.$emit('pressDelete')
    },
  },
}
</script>

<style lang="scss">
.delete-dialog {
  .modal-footer, .modal-header {
    border: none;
  }
  .modal-body {
    padding: 20px;
  }
  h3 {
    margin: 0;
    text-align: center;
    font-size: 24px;
  }
}
</style>