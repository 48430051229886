<template>
  <router-link :to="{ path }" class="custom-btn">
    <img src="@/assets/images/icons/edit.svg" alt="edit-btn" />
  </router-link>
</template>

<script>
export default {
  name: 'EditBtn',
  props: {
    path: String,
  },
}
</script>
